import React, { useEffect, useState } from "react";
import styles from "./CustomerStatement.module.css";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useNavigate } from "react-router-dom";

const userData = JSON.parse(localStorage.getItem("userData"));

const CustomerStatement = () => {
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Use navigate hook

  const fetchCustomerData = async () => {
    try {
      const response = await axiosPrivate(`/wallet/${userData?.id}`);
      if (response?.status === 200) {
        setData(response?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  // Fetch user data
  useEffect(() => {
    const getUser = async () => {
      try {
        if (!userData || !userData.id || !userData.userName) {
          navigate("/login");
        }

        const response = await axiosPrivate.get(`/user/${userData?.id}`);
        if (response.status === 200) {
          setUser(response?.data?.data);
          
        }
      } catch (err) {
       
      }
    };

    getUser();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>{userData?.userName}</h1>
        <h2>Wallet Balance: ₹{user?.wallet_balance || 0}</h2>
      </div>
      <div className={styles.statement}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((transaction, index) => (
              <tr key={index}>
                <td>
                  {transaction?.created_at?.split("T")[0]} -
                  {transaction?.created_at?.split("T")[1].slice(0, -8)}
                </td>
                <td>{transaction?.transaction_type}</td>
                <td
                  style={{
                    color:
                      transaction?.transaction_type === "Credit" ||
                      transaction?.transaction_type === "credit"
                        ? "green"
                        : "red",
                  }}
                >
                  ₹ {transaction.amount}
                </td>
                <td>{transaction.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerStatement;
