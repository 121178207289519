import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../FoodOrder/FoodOrder.module.css";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import { InputSwitch } from "primereact/inputswitch";
import { useLoader } from "../../context/Loader/LoaderProvider";
import QRCode from "react-qr-code";
import { Checkbox } from "primereact/checkbox";
import { MdQrCodeScanner } from "react-icons/md";

const FixedMeal = ({
  menuData,
  activeTab,
  orderData,
  showQr,
  hasOrderData,
  paymentPending,
  updateQr,
  cartData,
  setCartData
}) => {
  const [itemsList, setItemsList] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const { includeSundays, setIncludeSundays } = useLoader();
  const [allMealsCompleted, setAllMealsCompleted] = useState(false);
  const debounceTimeoutRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem("userData")) || [];

  const cartList = JSON.parse(localStorage.getItem("cartData")) || [];




  
 
  const fetchCartData = async () => {
    try {
      const response = await axiosPrivate.get(`/cart/${userData?.id}`);
      if (response.status === 200) {
        setCartData(response?.data?.data);

        const cartItemIds = response?.data?.data?.map(item => item?.menu_item_id)
        localStorage.setItem("cartData", JSON.stringify(menuData?.filter(menuItem => cartItemIds.includes(menuItem?.id)).map(item => ({...item , quantity : 1}))));

      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    setItemsList(menuData.map((item) => ({ ...item, quantity: 0 })));
  }, [menuData, activeTab]);

  useEffect(() => {
    fetchCartData();
  }, []);

  useEffect(() => {
    updateItemsList(menuData, cartData);
  }, [menuData, cartData, activeTab]);

  const updateItemsList = (menuData, cartData) => {
    setItemsList(
      menuData?.map((item) => {
        const currentCartItem = cartData?.find(
          (cartItem) => cartItem?.menu_item_id === item.id
        );

        return {
          ...item,
          quantity: currentCartItem?.quantity
            ? Number(currentCartItem?.quantity)
            : 0,
        };
      })
    );
  };

  const debouncedUpdateCart = useCallback(
    (id, newQuantity) => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(async () => {
        try {
          await axiosPrivate.post("/cart", {
            user_id: userData?.id,
            menu_id: id,
            quantity: newQuantity,
          });
          await fetchCartData();
        } catch (error) {
          showErrorToast(error.message);
        }
      }, 500);
    },
    [fetchCartData,  userData?.id]
  );

  const handleQuantityChange = async (id, action) => {
    const updatedItemsList = itemsList.map((item) =>
      item.id === id ? { ...item, quantity: action === "add" ? 1 : 0 } : item
    );
    setItemsList(updatedItemsList);

    const updatedItem = updatedItemsList.find((item) => item.id === id);
    if (updatedItem) {
      const newQuantity = action === "add" ? 1 : 0;
      debouncedUpdateCart(id, newQuantity);

      try {
        await axiosPrivate.post("/cart", {
          user_id: userData?.id,
          menu_id: id,
          quantity: newQuantity,
        });
        await fetchCartData();
      } catch (error) {
        showErrorToast(error.message);
      }
    }
  };


   const handleCartUpdate = (item, action) => {
     const updatedCartData = [...cartList];
     const itemIndex = updatedCartData?.findIndex(
       (cartItem) => cartItem.id === item.id
     );

     if (itemIndex >= 0) {
       const newQuantity = action === "add" ? 1 : 0;
       if (newQuantity > 0) {
         updatedCartData[itemIndex].quantity = newQuantity;
       } else {
         updatedCartData.splice(itemIndex, 1);
       }
     } else if (action === "add") {
       updatedCartData.push({ ...item, quantity: 1 });
     }

     localStorage.setItem("cartData", JSON.stringify(updatedCartData));
   };

  const handleCheckboxChange = (e) => {
    const selected = [...selectedMeals];
    if (e.checked) {
      selected.push(e.value);
    } else {
      selected.splice(selected.indexOf(e.value), 1);
    }
    setSelectedMeals(selected);
  };

  const generateQrCode = async () => {
    if (!selectedMeals.length) {
      showErrorToast("Please select at least one meal!");
      return;
    }

    try {
      const response = await axiosPrivate.post('/fixed-order', {
        user_id: userData?.id,
        payment_mode: orderData?.payment_mode,
        order_items_json: selectedMeals
      })
      if (response?.status === 200) {
        updateQr();
      }
      
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    let count = 0;
    orderData?.items?.forEach(item => {
      if (item?.menu_status === true) {
        count = count + 1;
      }
    })

    if (count === orderData?.items?.length) {
      setAllMealsCompleted(true);
    }

   }, []);



  return (
    <>
      {!showQr && !paymentPending ? (
        <>
          <div className={styles.itemsContainer}>
            <span style={{ color: "#666666", fontSize: "16px" }}>
              Billing Cycle: Day 1 to last day of every month
            </span>
            {itemsList?.map((item) => (
              <div
                key={item?.id}
                className={`${styles.item} ${
                  cartData.find((cartItem) => cartItem.id === item.id)
                    ? styles.inCart
                    : ""
                }`}
                style={{
                  background: item?.quantity ? "#f0f0f0" : "#fff",
                }}
              >
                <img
                  src={process.env.REACT_APP_MEDIA_URL + `${item?.image}`}
                  alt={item.name}
                  className={styles.itemImage}
                />
                <div className={styles.itemDetails}>
                  <h3 className={styles.itemName}>{item?.title}</h3>
                  <p className={styles.itemDescription}>{item.description}</p>
                  <div className={styles.itemFooter}>
                    <span className={styles.itemPrice}>₹ {item.price}</span>
                    <div className={styles.quantityControl}>
                      {item?.quantity ? (
                        <button
                          className={styles.quantityButton}
                          onClick={() =>
                           { handleQuantityChange(item.id, "remove")
                            handleCartUpdate(item , "remove")}
                          }
                        >
                          -
                        </button>
                      ) : (
                        <button
                          className={styles.quantityButton}
                            onClick={() => {
                              handleQuantityChange(item.id, "add")
                              handleCartUpdate(item , "add")
                            }}
                        >
                          +
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className='d-flex justify-content-end align-items-center w-100'
            style={{
              position: "relative",
              bottom: "40px",
              background: "#f5f5f5",
              height: "40px",
            }}
          >
            <span
              style={{ color: "#666666", fontSize: "16px" }}
              className='mr-3'
            >
              Include Sundays
            </span>
            <InputSwitch
              checked={includeSundays}
              onChange={(e) => setIncludeSundays(e.value)}
            />
          </div>
        </>
      ) : paymentPending ? (
        <div
          className={`d-flex justify-content-center align-items-center flex-column text-center`}
          style={{
            height:
              "calc(100dvh - ( var(--navbar-height) + var(--btn-wrapper-height) + 50px))",
          }}
        >
          <h4 style={{ color: "#333" }}>
            Please wait for the payment to be confirmed!
          </h4>
        </div>
      ) : Number(orderData?.include_sundays) === 0 &&  new Date().getDay() === 0 ? (
        <div
          className={`d-flex justify-content-center align-items-center flex-column text-center`}
          style={{
            height:
              "calc(100dvh - ( var(--navbar-height) + var(--btn-wrapper-height) + 50px))",
          }}
        >
          <h4 style={{ color: "#333" }}>
            Fixed Meal Not Available Today!
          </h4>
        </div>
      ) : (
        <>
          <div
            className={styles.checkboxContainer}
            style={{
              height:
                hasOrderData &&
                orderData?.order?.uu_id &&
                orderData?.order?.order_status !== "completed"
                  ? ""
                  : "calc(100dvh - ( var(--navbar-height) + var(--btn-wrapper-height) + 90px))",
            }}
          >
            <div className={styles.menuItems}>
              {orderData?.items?.map((item) => (
                <div
                  className={styles.checkboxGroup}
                  key={item?.menu_id}
                >
                  <Checkbox
                    inputId={item?.menu_id}
                    value={item}
                    onChange={handleCheckboxChange}
                    checked={selectedMeals.includes(item) || item.menu_status}
                    disabled={item.menu_status}
                  />
                  <label
                    htmlFor={item?.menu_id}
                    className={styles.checkboxLabel}
                  >
                    {item?.menu_title}
                  </label>
                </div>
              ))}
            </div>
            <button
              className={styles.generateQrButton}
              onClick={generateQrCode}
              disabled={allMealsCompleted}
              style={{
                backgroundColor: allMealsCompleted ? "grey" : "#ff5a5f",
              }}
            >
              <MdQrCodeScanner size={20} />
              <span className='ml-2'>Generate QR</span>
            </button>
          </div>
          {hasOrderData &&
          orderData?.order?.uu_id &&
          orderData?.order?.order_status !== "completed" ? (
            <div
              className={`${styles.qrContainer} d-flex justify-content-center align-items-center flex-column`}
            >
              <QRCode
                value={orderData?.order?.uu_id}
                size={250}
              />
              <span
                className='mt-2'
                style={{ color: "#555" }}
              >
                Scan this QR to order your meal for today!
              </span>
              <span
                className='mt-2'
                style={{ color: "#555" }}
              >
                Subscription ends in: {orderData?.remaining_days} days
              </span>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default FixedMeal;
