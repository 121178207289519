import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash} from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

const MenuCategoryList = () => {
  const [menuData, setmenuData] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const { isLoading, setIsLoading } = useLoader();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    if(roleId) {
      setDeleteConfirmationVisible(true);
    }
  }, [roleId]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("menuCategory");
      if (response.status === 200) {
        setmenuData(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const menuDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`menuCategory/${id}`);
      if (response.status === 200) {
        showSuccessToast("Category deleted successfully");
        fetchData();
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setRoleId(null)
    }
  };

  const menuListData = menuData.map((value, index) => {
    const buttons = [];
    buttons.push(
      <Link
        key={`editButton_${value.id}`}
        to={`/menu-category/edit/${value.id}`}
        style={{ border: "none", background: "none", padding: "6px" }}
        title='Edit'
      >
        <FaEdit
          color='green'
          size={13}
        />
      </Link>
    );
    buttons.push(
      <button
      type='button'
      onClick={() => {
        menuDeleteHandler(value.id);
      }}
      title='Delete'
      style={{ border: "none", background: "none", padding: "6px", textAlign: "left" }}
    >
      <FaTrash
        color='red'
        size={13}

      />
    </button>
    )
  
  
    return {
      ...value,
      index: index + 1,
      action: buttons.length > 0 ? buttons : "-",
    };
  });
  
  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='d-flex justify-content-between w-100'>
          <Link
            to='add'
            className='text-decoration-none '
           
          >
            <button
              type='button'
              className='btn btn-primary'
              data-toggle='modal'
              data-target='#exampleModal'
            >
              <i className='fe fe-plus mr-2' />
              Add
            </button>
          </Link>
          <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Search Category'
        />
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='content'>
            <div className='page-header'>
              <h4 className='m-1 pl-3'>Menu Categories</h4>
            </div>
            <div className='card'>
              <div className='card-body'>
                <DataTable
                  value={menuListData}
                  dataKey='id'
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rowsPerPageOptions={[10, 20, 30, 50]}
                  paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                  currentPageReportTemplate='{first} to {last} of {totalRecords}'
                  rows={10}
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    bodyStyle={{ textAlign: "left" }}
                    style={{ width: "8%" }}
                  />

                  <Column
                    field='title'
                    header='Menu Category Name'
                    sortable
                  />
                   <Column
                    field='description'
                    header='Details'
                    sortable
                  />
                  <Column
                    field='action'
                    header='Action'
                    sortable
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MenuCategoryList;
