import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaRegFilePdf } from "react-icons/fa6";
import { FaRegFileExcel } from "react-icons/fa";
import { FcClearFilters } from "react-icons/fc";
import { showErrorToast } from "../../utils/Toaster";
import { Calendar } from "primereact/calendar";
import * as XLSX from "xlsx";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";

const CategorySales = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [ordersData, setOrdersData] = useState([]);
   const { isLoading, setIsLoading } = useLoader();
  const [customDateSelceted, setCustomDateSelceted] = useState(false);
  const dt = useRef(null);

  const handleClearFilter = () => {
    setFromDate(null);
    setToDate(null);

    fetchOrdersData();

    setCustomDateSelceted(false);
  };

  const renderHeader = () => {
    return (
      <div className='row d-flex justify-content-between align-items-center pl-2 pr-2 '>
        <div>
          <button
            className='btn btn-primary ml-2'
            onClick={exportExcel}
          >
            <FaRegFileExcel
              size={17}
              className='mr-1'
            />
            Export as Excel
          </button>
        </div>

        <div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex flex-column'>
              <span>From: </span>
              <Calendar
                value={fromDate}
                onChange={(e) => setFromDate(e.value)}
                showIcon
                readOnlyInput
                dateFormat='dd-mm-yy'
                placeholder='dd-mm-yyyy'
              />
              {!fromDate && customDateSelceted ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select From Date!
                </div>
              ) : null}
            </div>
            <div className='d-flex flex-column ml-3'>
              <span className='ml-2'>To: </span>
              <Calendar
                value={toDate}
                onChange={(e) => setToDate(e.value)}
                readOnlyInput
                showIcon
                dateFormat='dd-mm-yy'
                placeholder='dd-mm-yyyy'
              />
              {!toDate && customDateSelceted && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select to Date!
                </div>
              )}
            </div>

            <button
              className='btn btn-primary ml-2 mt-3'
              onClick={() => {
                setCustomDateSelceted(true);
                fetchOrdersData();
              }}
              style={{ cursor: "pointer" }}
            >
              Apply
            </button>
            <FcClearFilters
              className=' ml-2 mt-3'
              size={25}
              onClick={handleClearFilter}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    );
  };

  const formatDateToYYYYMMDD = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
    const day = String(d.getDate()).padStart(2, "0"); // Add leading zero if necessary
    return `${year}-${month}-${day}`;
  };

  const exportExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Category_Sales_Report";
    const ws = XLSX.utils.json_to_sheet(
      ordersData.map((item) => ({
        Category: item?.category?.toUpperCase(),
        Items_Sold: item?.items_sold ? item?.items_sold : 0,
        Gross_Sales: item?.gross_sales ? item?.gross_sales : 0,
        Refunded_Amount: item?.refunds ? item?.refunds : 0,
        Net_Sales: item?.net_sales ? item?.net_sales : "-",
      }))
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
  };

  const fetchOrdersData = async () => {
    try {
      setIsLoading(true)
      let response;
      if (fromDate && toDate) {
        response = await axiosPrivate.post("reports/by-category", {
          from_date: formatDateToYYYYMMDD(fromDate),
          to_date: formatDateToYYYYMMDD(toDate),
        });
      } else {
        response = await axiosPrivate.post("reports/by-category", {
          from_date: fromDate,
          to_date: toDate,
        });
      }

      if (response.status === 200) {
        
        setOrdersData(
          response?.data
            ?.map((item, index) => ({
              ...item,
              index: index + 1,
            }))
            ?.filter((item) => item?.items_sold > 0)
        );
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error?.message);
      } else if (error.response?.status === 404) {
        setOrdersData([]);
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdersData();
  }, []);

  const header = renderHeader();

  let items_sold_total = 0;
  let gross_sales_total = 0;
  let refunded_total = 0;
  let net_total = 0;

  ordersData?.forEach((item) => {
    items_sold_total += Number(item.items_sold);
    gross_sales_total += Number(item.gross_sales?.toFixed(2));
    refunded_total += Number(item.refunds?.toFixed(2));
    net_total += Number(item.net_sales?.toFixed(2));
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className='table-responsive col-12 mb-4'
          style={{ minHeight: `calc(100dvh - 106px)` }}
        >
          <DataTable
            header={header}
            value={ordersData}
            style={{ overflow: "scroll" }}
            editMode='row'
            paginator
            rowsPerPageOptions={[20, 40, 60, 80]}
            paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
            currentPageReportTemplate='{first} to {last} of {totalRecords}'
            rows={20}
            size='small'
            dataKey='index'
            ref={dt}
            showGridlines
          >
            <Column
              field='category'
              header='Category'
              body={(rowData) => rowData?.category.toUpperCase()}
              sortable={true}
            />

            <Column
              field='items_sold'
              header={() => (
                <span>Items Sold (Total:₹ {items_sold_total})</span>
              )}
              sortable={true}
            />
            <Column
              field='gross_sales'
              header={() => (
                <span>Gross Sales (Total:₹ {gross_sales_total})</span>
              )}
              body={(rowData) =>
                rowData?.gross_sales ? rowData.gross_sales?.toFixed(2) : "-"
              }
              sortable={true}
            />
            <Column
              field='refunds'
              header={() => <span>Refunds (Total:₹ {refunded_total})</span>}
              body={(rowData) =>
                rowData?.refunds ? rowData.refunds?.toFixed(2) : "-"
              }
              sortable={true}
            />
            <Column
              field='net_sales'
              header={() => <span>Net Sales (Total:₹ {net_total})</span>}
              body={(rowData) =>
                rowData?.net_sales ? rowData.net_sales?.toFixed(2) : "-"
              }
              sortable={true}
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default CategorySales;
