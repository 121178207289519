import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [user, setUser] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const navigate = useNavigate(); // Use navigate hook

  const departments = [
    { id: "1", name: "Designing" },
    { id: "2", name: "Manufacturing" },
    { id: "3", name: "Marketing" },
  ];

  // Fetch user data
  useEffect(() => {
    const getUser = async () => {
      try {
        if (!userData || !userData.id || !userData.userName) {
          navigate("/login");
        }

        const response = await axiosPrivate.get(`/user/${userData?.id}`);
        if (response.status === 200) {
          setUser(response?.data?.data);
          updateUserFormik.setValues(
          
            response?.data?.data
          );
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    getUser();
  }, []);

  // Validation schema for updating user information
  const userSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Invalid mobile number")
      .required("Mobile number is required"),
    emp_code: Yup.string().required("Employee code is required"),
    newPassword: Yup.string().min(
      6,
      "Password should be at least 6 characters"
    ),
  });

  // Formik hook for updating user information
  const updateUserFormik = useFormik({
    initialValues: {
      name: user?.name || "",
      email: user?.email || "",
      mobile: user?.mobile || "",
      emp_code: user?.emp_code || "",
      department: user?.department || "",
      newPassword: "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        const { name, email, mobile, emp_code, department, newPassword  } =
          values; // Destructure only the required fields
        const payload = { name, email, mobile, emp_code, department };
        if (newPassword) {
          payload.password = newPassword;
        }
        payload.role = user?.role || "";
        const response = await axiosPrivate.put(`user/${user?.id}`, payload); // Send only the required fields in the payload

        if (response.status === 200) {
          showSuccessToast("User information updated successfully");
          window.location.reload();
        }
      } catch (err) {
        if (err.response?.data?.message.includes('emp_code_unique')) {
          showErrorToast("Employee Code already exists!")
        }
        else showErrorToast(err.message);
      }
    },
  });

  // JSX for rendering user information update form
  const renderUserInfoForm = () => (
    <form onSubmit={updateUserFormik.handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label
              htmlFor='name'
              className='form-label'
            >
              Name *
            </label>
            <input
              id='name'
              type='text'
              name='name'
              className='form-control'
              placeholder='Enter your name'
              value={updateUserFormik.values.name}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.name && updateUserFormik.errors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {updateUserFormik.errors.name}
              </div>
            )}
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label
              htmlFor='email'
              className='form-label'
            >
              Email *
            </label>
            <input
              id='email'
              type='email'
              name='email'
              className='form-control'
              placeholder='Enter your email'
              value={updateUserFormik.values.email}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.email &&
              updateUserFormik.errors.email && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updateUserFormik.errors.email}
                </div>
              )}
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label
              htmlFor='mobile'
              className='form-label'
            >
              Mobile *
            </label>
            <input
              id='mobile'
              type='text'
              name='mobile'
              className='form-control'
              placeholder='Enter your mobile number'
              value={updateUserFormik.values.mobile}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.mobile &&
              updateUserFormik.errors.mobile && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updateUserFormik.errors.mobile}
                </div>
              )}
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label
              htmlFor='emp_code'
              className='form-label'
            >
              Employee Code *
            </label>
            <input
              id='emp_code'
              type='text'
              name='emp_code'
              className='form-control'
              placeholder='Enter your employee code'
              value={updateUserFormik.values.emp_code}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.emp_code &&
              updateUserFormik.errors.emp_code && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updateUserFormik.errors.emp_code}
                </div>
              )}
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label className='form-label'>Department</label>
            <select
              className='form-control show-tick'
              id='department'
              name='department'
              value={updateUserFormik.values.department}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            >
              <option value=''>--Select Department--</option>
              {departments.map((dept) => (
                <option
                  key={dept.id}
                  value={dept.name}
                >
                  {dept.name}
                </option>
              ))}
            </select>
            {updateUserFormik.touched.department &&
              updateUserFormik.errors.department && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updateUserFormik.errors.department}
                </div>
              )}
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label
              htmlFor='newPassword'
              className='form-label'
            >
              New Password
            </label>
            <input
              id='newPassword'
              type='password'
              name='newPassword'
              className='form-control'
              placeholder='Enter new password'
              value={updateUserFormik.values.newPassword}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.newPassword &&
              updateUserFormik.errors.newPassword && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updateUserFormik.errors.newPassword}
                </div>
              )}
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label
              htmlFor='role'
              className='form-label'
            >
              Role
            </label>
            <input
              id='role'
              type='text'
              name='role'
              className='form-control'
              value={userData?.role_name}
              disabled
            />
          </div>
        </div>

        <div className='col-12'>
          <button
            type='submit'
            className='btn btn-primary'
          >
            Update Information
          </button>
        </div>
      </div>
    </form>
  );

  // JSX for rendering user profile page
  return (
    <div className='content'>
      <div className='section-body mt-2'>
        <div className='card'>
          <div className='card-header'>
            <h5>User Information</h5>
          </div>
          <div className='card-body'>{user && renderUserInfoForm()}</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
