import React, { useEffect, useState } from "react";
import styles from "./CustomerFooter.module.css";
import { Link, useNavigate } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const CustomerFooter = ({
  onDetailsClick,
  isCheckoutPage,
  totalQuantity,
  checkoutData,
  totalPrice,
  isFixedMeal,
  includeSundays,
  orderDetails,
  setOrderDetails,
 
  cartData,
  setCartData
 
}) => {


 

  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem('userData')) || {};

  const cartList = JSON.parse(localStorage.getItem('cartData')) || [];


 


  
  const createOrder = async (orderData, paymentMode) => {
    const order_items_json = JSON.stringify(
      orderData?.map((item) => ({
        menu_id: item?.id,
        menu_name: item?.title,
        menu_image: item?.image,
        menu_type: item?.menu_type,
        quantity: item?.quantity,
        price: item?.price,
        remark: item?.remark,
        price_total: item?.price * item?.quantity,
      }))
    );
    try {
      const response = await axiosPrivate.post("order", {
        user_id: userData?.id,
        payment_mode: paymentMode,
        order_items_json: order_items_json,
        total_amount: totalPrice,
        net_amount: totalPrice,
      });
      
      if (response?.status === 200) {
        
        setOrderDetails(response?.data?.Data);
       
        if (paymentMode === 'upi') {
          showSuccessToast("Order Placed successfully!,Please Pay at Counter");
          navigate('/order-food')
        } else {
          showSuccessToast("Order Placed successfully!");
           navigate("/checkout"); 
        }

        localStorage.setItem('cartData', JSON.stringify([]));
      }
        
    } catch (error) {
      showErrorToast(error?.response?.data?.message);
        
      }
  }
  


   const createSubscription = async (paymentMode) => {
     try {
      
       const response = await axiosPrivate.post("/subscription", {
         include_sundays: includeSundays,
         user_id: userData?.id,
         payment_mode : paymentMode,
       });

       if (response.status === 200) {
          localStorage.setItem("cartData", JSON.stringify([]));
        navigate('/order-food')
         
       }
     } catch (error) {
            showErrorToast(error?.response?.data?.message);
     
     } 
   };


  

  if (isCheckoutPage) {
    
    return (
      <div className={styles.footerWrapper}>
        <button
          className={styles.checkoutButton}
          style={{ width: "50%" }}
          onClick={() =>{
            isFixedMeal
              ? createSubscription("wallet")
              : createOrder(cartList, "wallet");
          }}
        >
          Pay by Wallet
        </button>
        <div
          className='dropup text-center'
          style={{ width: "50%" }}
        >
          <button
            className={`${styles.checkoutButton} dropdown-toggle`}
            style={{ background: "#ffffff", color: "#FF4C29" , width: '100%'}}
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            Pay at Counter
          </button>
          <div className='dropdown-menu'>
           
            <a
              className='dropdown-item'
              href='# '
              onClick={() => {
                console.log('Pay at Counter')
                isFixedMeal ? 
                  createSubscription('upi')
                  :
                createOrder(cartList, 'upi')
              }}
            >
              Pay with UPI
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.footerWrapper}>
      {cartData?.length ? (
        <div className={styles.detailsWrapper}>
          <span>{cartData?.length} items added. </span>
          <span
            className={styles.detailsLink}
            onClick={onDetailsClick}
          >
            Details
          </span>
        </div>
      ) : (
        <div></div>
      )}
      {cartData?.length ? (
        <Link
          to='/checkout'
          className={styles.checkoutButton}
        >
          Checkout
        </Link>
      ) : (
        <button
          className={styles.checkoutButton}
          disabled
          style={{ background: "grey", cursor: "unset" }}
        >
          Checkout
        </button>
      )}
    </div>
  );
};

export default CustomerFooter;
