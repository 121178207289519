import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import BackButton from "../../assets/images/backButton.svg";
import styles from "../Checkout/Checkout.module.css";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";

const GuestQr = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  const { isLoading, setIsLoading } = useLoader();
  const [guestOrderData, setGuestOrderData] = useState({});
  const [hasOrderData, setHasOrderData] = useState(false);

  const getGuestOrder = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `/order-reference/${userData?.id}`
      );
      if (response.status === 200) {
        setGuestOrderData(response?.data);
        setHasOrderData(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGuestOrder();
  }, []);

  console.log(guestOrderData);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.bodyWrapper}>
          <div className={styles.header}>
            <Link to='/order-food'>
              <img
                src={BackButton}
                alt='back_button'
              />
            </Link>
            <h2>Guest QR</h2>
          </div>
          <div
            className={`${styles.qrContainer} d-flex justify-content-center align-items-center flex-column`}
          >
            {hasOrderData && guestOrderData.uu_id ? (
              <>
                <QRCode
                  value={guestOrderData.uu_id}
                  size={320}
                />
                <span
                  className='mt-2'
                  style={{ color: "#555" }}
                >
                  Scan this QR to order your meal for today!
                </span>
              </>
            ) : (
              <span
                className='mt-2'
                style={{ color: "#555" }}
              >
                No QR code available. Please try again later.
              </span>
            )}
           
          </div>
        </div>
      )}
    </>
  );
};

export default GuestQr;
