import React, { useEffect, useState } from 'react'
import styles from "./CustomerHeader.module.css";
import CustomerLogo from "../../assets/images/customer_logo.png";
import { Link, useNavigate } from 'react-router-dom';
import axiosPrivate from '../../hooks/axiosPrivate';
import { MdQrCodeScanner } from "react-icons/md";
const CustomerHeader = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  

  const [guestOrderData, setGuestOrderData] = useState({});
  


    const getGuestOrder = async () => {
      try {
        const response = await axiosPrivate.get(
          `/order-reference/${userData?.id}`
        );
        if (response.status === 200) {
          setGuestOrderData(response?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getGuestOrder();
    }, []);
  

  const navigate = useNavigate(); 
  const logoutHandler = () => {
    // Remove user data from local storage
    localStorage.removeItem("userData");
    // Navigate to the login page
    navigate("/login");
  };
  return (
    <>
      {/* Customer Header */}
      <nav className={`${styles.navbar} page-header`}>
        <Link to='order-food'>
          <img
            src={CustomerLogo}
            alt='logo'
          />
        </Link>
        <div className='right'>
          <div className='d-flex justify-content-center align-items-center'>
            {/* User profile dropdown */}
            <div className='dropdown d-flex'>
              <a
                href='/#'
                className='nav-link icon d-none d-flex btn btn-default btn-icon ml-1 justify-content-center align-items-center'
                data-toggle='dropdown'
              >
                <i className='fa fa-user'></i>
                <span
                  style={{
                    marginTop: "1px",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                >
                  {userData.userName}
                </span>
              </a>
              <div className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'>
                {guestOrderData?.uu_id ? (
                  <a
                    className='dropdown-item'
                    href='/guest-qr'
                  >
                    <MdQrCodeScanner />
                    <span className='ml-2'>Guest QR</span>
                  </a>
                ) : null}

                <a
                  className='dropdown-item'
                  href='/user-profile'
                >
                  <i className='dropdown-icon fe fe-user'></i> Profile
                </a>
                <a
                  className='dropdown-item'
                  onClick={logoutHandler}
                  href='# '
                >
                  <i className='dropdown-icon fe fe-log-out'></i> Sign out
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default CustomerHeader