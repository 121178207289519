import { Fragment } from "react";
import SideBar from './SideBar';
import Header from './Header';
import Footer from './Footer'
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = (props) => {

  const userData = JSON.parse(localStorage.getItem('userData')) || {};

  return (
    <Fragment>
      <div className='font-montserrat'>
        <div id='main_content'>
          <div className=''>
            {userData?.role_name === "admin" ||
            userData?.role_name === "Admin" ? (
              <div>
                <SideBar />
              </div>
            ) : null}

            <div
              className='page'
              style={{
                width:
                  userData?.role_name === "admin" ||
                  userData?.role_name === "Admin"
                    ? "calc(100% - 90px)"
                    : "100%",
                left:
                  userData?.role_name === "admin" ||
                  userData?.role_name === "Admin"
                    ? "90px"
                    : "0px",
              }}
            >
              <Header />
              <Outlet />
              <Footer />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Main;
