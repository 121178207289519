import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const AddEditMenu = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [text, setText] = useState("Add");
  const [foodCounterData, setFoodCounterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [menuCategoryData, setMenuCategoryData] = useState([]);

  useEffect(() => {
    const getMenuDetails = async () => {
      try {
        if (id) {
          const res = await axiosPrivate.get(`menu/${id}`);
          formik.setValues(res?.data?.data);
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    if (id) {
      getMenuDetails();
    }
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.get("foodCounter");
        if (response.status === 200) {
          setFoodCounterData(response.data?.data);
        }
      } catch (error) {
        if (error.response?.status !== 404) {
          showErrorToast(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
    const fetchMenuCategory = async () => {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.get("menuCategory");
        if (response.status === 200) {
          setMenuCategoryData(response.data?.data);
        }
      } catch (error) {
        if (error.response?.status !== 404) {
          showErrorToast(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchMenuCategory();
    fetchData();
  }, []);

  // Form validation schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Food title is required"),
    description: Yup.string().required("Description is required"),
    food_counter_id: Yup.number().required("Food counter name is required"),
    menu_type: Yup.string().required(" Menu type is required"),
    menu_category_id: Yup.number().required (" Menu category is required"),
    price: Yup.number().required("Price is required"),
    image: Yup.mixed().required("Image is required"),
  });

  // Initial form values
  const initialValues = {
    title: "",
    description: "",
    food_counter_id: "",
    price: "",
    image: null,
    menu_type: "",
    menu_category_id: "",
  };

  // Form submission handling
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (id) {
        await axiosPrivate.post(`menu/${id}`, formData);
        navigate("/menu");
        showSuccessToast("Menu updated successfully");
      } else {
        await axiosPrivate.post(`menu`, formData);
        navigate("/menu");
        showSuccessToast("Menu added successfully");
      }
    } catch (err) {
      showErrorToast(err.message);
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (id) {
      setText("Edit");
    }
  }, [id]);

  //handle Image Url change
  const isFileObject = formik.values?.image instanceof File;
  const imageUrl = isFileObject
    ? URL.createObjectURL(formik.values?.image)
    : `${process.env.REACT_APP_MEDIA_URL}${formik.values?.image}`;


  return (
    <div className='content'>
      <div
        className='section-body d-flex justify-content-center align-items-center'
        style={{ minHeight: `calc(100dvh - 106px)` }}
      >
        <div
          className='card '
          style={{ width: "30%" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className='card-header'>
              <strong>{text} Menu</strong>
            </div>
            <div className='card-body'>
              <div className='row clearfix'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Food Title</label>
                    <input
                      id='title'
                      name='title'
                      type='text'
                      className='form-control'
                      placeholder='Food Title*'
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue("title", formik.values.title);
                      }}
                      value={formik.values?.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.title}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Description</label>
                    <input
                      id='description'
                      name='description'
                      type='text'
                      className='form-control'
                      placeholder='Description *'
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue(
                          "description",
                          formik.values?.description
                        );
                      }}
                      value={formik.values?.description}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.description}
                        </div>
                      )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Menu Item Type</label>
                    <select
                      id='menu_type'
                      name='menu_type'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.menu_type}
                    >
                      <option
                        value=''
                        label='Select Type'
                      />
                      <option value='Regular'>Regular</option>
                      <option value='Fixed Meal'>Fixed Meal</option>
                    </select>
                    {formik.touched.menu_type && formik.errors.menu_type && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.menu_type}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Menu Category</label>
                    <select
                      id='menu_category_id'
                      name='menu_category_id'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.menu_category_id}
                    >
                      <option
                        value=''
                        label='Select Category'
                      />
                      {menuCategoryData.map((counter) => (
                        <option
                          key={counter.id}
                          value={counter.id}
                        >
                          {counter.title}
                        </option>
                      ))}
                    </select>
                    {formik.touched.menu_category_id &&
                      formik.errors.menu_category_id && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.menu_category_id}
                        </div>
                      )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Food Counter</label>
                    <select
                      id='food_counter_id'
                      name='food_counter_id'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.food_counter_id}
                    >
                      <option
                        value=''
                        label='Select food counter'
                      />
                      {foodCounterData.map((counter) => (
                        <option
                          key={counter.id}
                          value={counter.id}
                        >
                          {counter.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.food_counter_id &&
                      formik.errors.food_counter_id && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.food_counter_id}
                        </div>
                      )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Image</label>
                    <input
                      id='image'
                      name='image'
                      type='file'
                      className='form-control'
                      onChange={(event) => {
                        formik.setFieldValue(
                          "image",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <>
                      {formik.values?.image && (
                        <img
                          width={70}
                          height={70}
                          className='mt-2'
                          src={imageUrl}
                          alt='food-img'
                          loading='lazy'
                        />
                      )}
                    </>
                    {formik.touched.image && formik.errors.image && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.image}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Price</label>
                    <input
                      id='price'
                      name='price'
                      type='number'
                      className='form-control'
                      placeholder='Price *'
                      onChange={(event) => {
                        // Prevent negative values and 'e'
                        
                        if (
                          event.keyCode === 8 ||
                          event.keyCode === 46 ||
                          event.keyCode === 9
                        ) {
                          return; // Allow the keypress
                        }
                        if (
                          event?.target?.value?.length >= 10 ||
                          event.keyCode === 69 ||
                          event.keyCode === 189 ||
                          parseFloat(event?.target?.value) < 0
                        ) {
                          event.preventDefault();
                        } else {
                          formik.setFieldValue("price", event?.target?.value);
                        }
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue("price", formik.values.price);
                      }}
                      value={formik.values?.price}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.price}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12 text-right'>
                  <hr className='mt-4' />
                  <button
                    type='button'
                    className='btn btn-secondary mx-1'
                    onClick={() => navigate("/menu")}
                  >
                    CLOSE
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditMenu;
