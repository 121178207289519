import React, { useEffect, useState } from "react";
import FoodOrder from "../../customer/FoodOrder/FoodOrder";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import styles from "./CreateOrder.module.css";
import { InputSwitch } from "primereact/inputswitch";
import Select from "react-select";
import axiosPrivate from "../../hooks/axiosPrivate";
import axios from "axios";

const CreateOrder = () => {
  const [regularMealData, setRegularMealData] = useState([]);
  const [activeTab, setActiveTab] = useState("orderFood");
  const { isLoading, setIsLoading } = useLoader();
  const [isDiscountActive, setIsDiscountActive] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionErrorGuest, setDescriptionErrorGuest] = useState(false);
  const [userError, setUserError] = useState(false); // State for user error
  const [users, setUsers] = useState([]); // State to store users list
  const [selectedUser, setSelectedUser] = useState(null); // State to store selected reference user
  const [cartData, setCartData] = useState([]); // State for cart data
  const [clearSearch, setClearSearch] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const fetchMenu = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/menu"); // Using axios without axiosPrivate
      if (response.status === 200) {
        const Data = response?.data?.data;
        setRegularMealData(
          Data?.filter((item) => item.menu_type === "Regular")
        );
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/user"); // Using axios without axiosPrivate
      if (response.status === 200) {
        setUsers(
          response.data.data
            ?.sort((a, b) =>
              a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())
            )
            .map((user) => ({
              value: user.id,
              label: `${user.name} - ${user.emp_code}`,
            }))
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMenu();
    if (userData?.role_name === "receptionist") {
      fetchUsers();
    }
  }, []);

  const handleCartUpdate = (item, delta) => {
    const updatedCartData = [...cartData];
    const itemIndex = updatedCartData.findIndex(
      (cartItem) => cartItem.id === item.id
    );

    if (itemIndex >= 0) {
      const newQuantity = updatedCartData[itemIndex].quantity + delta;
      if (newQuantity > 0) {
        updatedCartData[itemIndex].quantity = newQuantity;
      } else {
        updatedCartData.splice(itemIndex, 1);
      }
    } else if (delta > 0) {
      updatedCartData.push({ ...item, quantity: 1 });
    }

    setCartData(updatedCartData);
  };

  const calculateTotalPrice = () => {
    return cartData.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const createOrder = async (orderData, paymentMode) => {
    const totalPrice = calculateTotalPrice();

    if (isDiscountActive && !description) {
      setDescriptionError(true);
      showErrorToast("Description is required when a discount is applied.");
      return;
    }

    if (
      (userData?.role_name === "receptionist" ||
        userData?.role_name === "Receptionist") &&
      !description
    ) {
      setDescriptionErrorGuest(true);
      showErrorToast("Description is required.");
      return;
    }

    if (
      (userData?.role_name === "receptionist" ||
        userData?.role_name === "Receptionist") &&
      !selectedUser
    ) {
      setUserError(true);
      showErrorToast("Reference User is required.");
      return;
    }

    try {
      const response = await axiosPrivate.post("order", {
        user_id: userData?.id,
        reference_id: selectedUser ? selectedUser.value : null, // Include reference user id
        payment_mode: paymentMode,
        order_items_json: JSON.stringify(orderData.map(item => ({
          menu_id: item?.id, 
          menu_name: item?.title,
          menu_image: item?.image,
          menu_type: item?.menu_type,
          quantity: item?.quantity,
          price: item?.price,
          remark: item?.remark,
          price_total: (item?.price * item?.quantity)
          
        }))),
        total_amount: totalPrice,
        net_amount: paymentMode === "guest" ? 0 : total,
        discount: paymentMode === "guest" ? "100" : discount,
        discount_description: description,
      });

      if (response?.status === 200) {
        showSuccessToast("Order Placed successfully!");
        if (
          userData?.role_name !== "receptionist" &&
          userData?.role_name !== "Receptionist"
        ) {
          printKot(userData?.userName, response?.data?.Data?.uu_id);
        }
        setCartData([]);
        setClearSearch((prev)=> !prev);
         localStorage.setItem("cartData", JSON.stringify([]));
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setSelectedUser(null);
      setDescription("");
      setIsDiscountActive(false);
      setDiscount(0)
      setDescriptionError(false);
      setDescriptionErrorGuest(false);
    }
  };

  const totalPrice = calculateTotalPrice();
  const taxAndOtherCharges = 0; // Static value for example purposes
  const discountAmount = (totalPrice * discount) / 100;

  // Calculate the total price after applying the discount
  let totalAfterDiscount = totalPrice - discountAmount;
  let total = totalAfterDiscount + taxAndOtherCharges;

  if (
    total < 0 ||
    userData?.role_name === "receptionist" ||
    userData?.role === 5
  ) {
    total = 0;
    totalAfterDiscount = total + taxAndOtherCharges;
  }

  useEffect(() => {
    if (!isDiscountActive) {
      setDiscount(0);
      setDescription("");
    }
  }, [isDiscountActive]);
  

  const printKot = async (userName, orderId) => {
    try {
      const response = await axios.post(
        `http://localhost:8000/api/print-receipt`,
        {
          user_name: userName,
          order_id: orderId,
        }
      );
      if (response?.status === 200) {
        showSuccessToast("Please receive your receipt!");
      }
    } catch (error) {
      // showErrorToast(error.message);
    }
  }

  return (
    <>
      <div className={styles.orderContainer}>
        <div className={styles.menuListContainer}>
          <FoodOrder
            menuData={regularMealData}
            activeTab={activeTab}
            dynamicHeight='calc(100dvh - 160px)'
            setCartData={setCartData}
            cartData={cartData}
            isAdminView={true}
            clearSearch = {clearSearch}
          />
        </div>

        <div className={styles.bodyWrapper}>
          <div className={styles.header}>
            <h2>Checkout</h2>
          </div>
          <div className={styles.itemsContainer}>
            {cartData?.length ? (
              cartData?.map((item) => (
                <div
                  key={item.id}
                  className={styles.item}
                >
                  <img
                    src={process.env.REACT_APP_MEDIA_URL + `${item?.image}`}
                    alt={item?.title}
                    className={styles.itemImage}
                  />
                  <div className={styles.itemDetails}>
                    <div className='d-flex flex-column'>
                      <h3 className={styles.itemName}>{item.title}</h3>
                      <span className={styles.itemPrice}>₹ {item.price}</span>
                      {item.remark && (
                        <p className={styles.itemRemark}>
                          Remark: {item.remark}
                        </p>
                      )}
                    </div>
                    <div className={styles.quantityControl}>
                      <button
                        className={styles.quantityButton}
                        onClick={() => handleCartUpdate(item, -1)}
                        disabled={
                          !cartData.find((cartItem) => cartItem.id === item.id)
                        }
                      >
                        -
                      </button>
                      <span className={styles.quantity}>
                        {cartData.find((cartItem) => cartItem.id === item.id)
                          ?.quantity || 0}
                      </span>
                      <button
                        className={styles.quantityButton}
                        onClick={() => handleCartUpdate(item, +1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <span>Please Choose your Order First!</span>
            )}
          </div>

          <div className={styles.billDetails}>
            <h3>Bill Details</h3>

            {userData?.role_name !== "receptionist" &&
            userData?.role_name !== "Receptionist" ? (
              <div
                className='d-flex justify-content-between w-100'
                style={{ background: "#f5f5f5" }}
              >
                <span
                  style={{ color: "#666666", fontSize: "16px" }}
                  className='mb-3'
                >
                  Add Discount
                </span>
                <InputSwitch
                  checked={isDiscountActive}
                  onChange={(e) => setIsDiscountActive(e.value)}
                />
              </div>
            ) : null}

            {isDiscountActive ? (
              <div className='d-flex flex-column'>
                <div className='form-group'>
                  <label
                    htmlFor='discountValue'
                    className='form-label'
                  >
                    Discount %
                  </label>
                  <div className='d-flex'>
                    <input
                      className='form-control w-50'
                      type='number'
                      id='discountValue'
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label
                    htmlFor='discountDescription'
                    className='form-label'
                  >
                    Description
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    id='discountDescription'
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setDescriptionError(false);
                    }}
                  />
                  {descriptionError && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "3px",
                      }}
                    >
                      Description is required when a discount is applied.
                    </div>
                  )}
                </div>
              </div>
            ) : null}

            {userData?.role_name === "receptionist" ||
            userData?.role_name === "Receptionist" ? (
              <>
                <div className='form-group w-100 mt-3'>
                  <label
                    htmlFor='referenceUser'
                    className='form-label'
                  >
                    Reference User
                  </label>
                  <Select
                    value={selectedUser}
                    onChange={(selectedOption) => {
                      setSelectedUser(selectedOption);
                      setUserError(false);
                    }}
                    options={users}
                    placeholder='Select User'
                  />
                  {userError && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "3px",
                      }}
                    >
                      Reference User is required.
                    </div>
                  )}
                </div>

                <div className='form-group mt-0'>
                  <label
                    htmlFor='discountDescription'
                    className='form-label'
                  >
                    Description
                  </label>
                  <textarea
                    row={5}
                    className='form-control w-100'
                    id='discountDescription'
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setDescriptionErrorGuest(false);
                    }}
                  ></textarea>

                  {descriptionErrorGuest && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "3px",
                      }}
                    >
                      {" "}
                      Description is required.
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            <div className={styles.billItems}>
              <div className={styles.billItem}>
                <span>Subtotal</span>
                <span>₹ {totalPrice.toFixed(2)}</span>
              </div>
              {/* <div className={styles.billItem}>
                <span>Tax and Other Charges</span>
                <span>₹ {taxAndOtherCharges.toFixed(2)}</span>
              </div> */}
              <div className={styles.billItem}>
                <span>Discount</span>
                <span>₹ {discountAmount.toFixed(2)}</span>
              </div>
              <div className={styles.billTotal}>
                <span>Total</span>
                <span>₹ {totalAfterDiscount.toFixed(2)}</span>
              </div>
            </div>
          </div>
          <hr />
          <div style={{ width: "100%" }}>
            <div
              className='dropup text-center pt-3 d-flex justify-content-end'
              style={{ background: "#f5f5f5" }}
            >
              <button
                className={`${styles.checkoutButton} dropdown-toggle `}
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Place Order
              </button>

              <div className='dropdown-menu'>
                <a
                  className='dropdown-item'
                  href='# '
                  onClick={() => {
                    if (cartData?.length > 0) {
                      if (userData?.role_name === "receptionist") {
                        createOrder(cartData, "guest");
                      } else {
                        createOrder(cartData, "upi");
                      }
                    } else {
                      showErrorToast("Please Select at least one item!");
                    }
                  }}
                >
                  {userData?.role_name === "receptionist"
                    ? `Create for guest`
                    : `Paid by UPI`}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrder;
