import { Fragment, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import CustomerHeader from "../customer/CustomerHeader/CustomerHeader";

import styles from "./CustomerMain.module.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../hooks/axiosPrivate";
import { showSuccessToast } from "../utils/Toaster";

const CustomerMain = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const navigate = useNavigate()




  
  const logoutHandler = () => {
    // Remove user data from local storage
    localStorage.removeItem("userData");
    // Navigate to the login page
    navigate("/login");
  };




    const handleSubmit = async (values) => {
      try {
        
        const response = await axiosPrivate.post(`update-password`, values);
        
        if (response?.status === 200) {
          showSuccessToast("Password Changed Successfully!");
          showSuccessToast("Please Log in again with new password")
          logoutHandler();
        }
      } catch (error) {}
    };



  const formik = useFormik({
    initialValues: {
      name: userData?.userName || "",
      email: userData?.email || "",
      mobile: userData?.mobile || "",
      role: userData?.role || "",
      password: "",
      first_login: 0
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must contain at least 6 characters")
        .required("New Password is required"),
    }),
    onSubmit: handleSubmit,
  });


 

  return (
    <Fragment>
      <div
        className={`font-montserrat d-flex justify-content-center align-items-center ${styles.customerParent}`}
        style={{
          background: "#ebebeb" /* fallback for old browsers */,

          height: "100dvh",
          overflow: "hidden",
        }}
      >
        <>
          {userData?.first_login ? (
            <>
             
                <div
                  className='card d-flex  align-items-center m-3 flex-column justity-content-center'
                  style={{ width: "430px", height: "250px" }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <div className='card-body'>
                      <div className='card-title'>
                        Please Reset your Password to Continue{" "}
                      </div>
                      <div className='form-group'>
                        <label className='form-label' htmlFor="password">New Password*</label>

                        <input
                          type='password'
                          name='password'
                          id='password'
                          className='form-control'
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.password}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                        type='submit'
                        id='submitBtn'
                        name='submitBtn'
                          className='btn btn-primary btn-block'
                          value='Continue'
                        />
                      </div>
                    </div>
                  </form>
                </div>
             
            </>
          ) : (
            <div className={styles.customerWrapper}>
              <CustomerHeader />
              <Outlet />
            </div>
          )}
        </>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default CustomerMain;
