import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import CustomerLogo from "../../assets/images/customer_logo.png";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Link, useNavigate } from "react-router-dom";
import styles from "./CustomerProfile.module.css";

const CustomerProfile = () => {
  const [user, setUser] = useState(null);

  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  const navigate = useNavigate(); // Use navigate hook

  useEffect(() => {
    // Redirect to login page if userData is not present or essential properties are missing
    if (!userData || !userData.id || !userData.userName) {
      navigate("/login");
      window.location.reload();
    }
  }, [navigate, userData]);

  // Fetch user data
  useEffect(() => {
    const getUser = async () => {
      try {
        if (!userData || !userData.id || !userData.userName) {
          navigate("/login");
        }
       
        const response = await axiosPrivate.get(`/user/${userData?.id}`)
        if (response.status === 200) {
          setUser(response?.data?.data);
            updateUserFormik.setValues({
              ...userData,
              name: userData?.userName || "",
              email: userData?.email || "",
              mobile: userData?.mobile || "",
              wallet_balance: response?.data?.data?.wallet_balance || "0",
            });
        }

      } catch (err) {
        showErrorToast(err.message);
      }
    };

    getUser();
  }, []);

  // Validation schema for updating user information
  const userSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  // Formik hook for updating user information
  const updateUserFormik = useFormik({
    initialValues: {
      name: user?.name || "",
      password: user?.password || "",
      email: user?.email || "",
      mobile: user?.mobile || "",
      wallet_balance: user?.wallet_balance || "",
      role: user?.role || "",
      department: user?.department || "",
      emp_code : user?.emp_code || "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        const { name, password , role ,email , mobile , emp_code , department} = values; // Destructure only the required fields
        const response = await axiosPrivate.put(`user/${user.id}`, {
          name,
          password,
          mobile,
          email,
          role,
          emp_code,
          department
        }); // Send only the required fields in the payload

        if (response.status === 200) {
          localStorage.removeItem("userData");
          navigate("/login");
          showSuccessToast("User information updated successfully");
       }
       
        
      } catch (err) {
        showErrorToast(err.message);
      }
    },
  });

  // JSX for rendering user information update form
  const renderUserInfoForm = () => (
    <form onSubmit={updateUserFormik.handleSubmit}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group'>
            <label htmlFor='name'>Name *</label>
            <input
              id='name'
              type='text'
              name='name'
              className='form-control'
              placeholder='Enter your name'
              value={updateUserFormik.values.name}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.name && updateUserFormik.errors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {updateUserFormik.errors.name}
              </div>
            )}
          </div>
        </div>

        <div className='col-md-12'>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              id='email'
              type='email'
              name='email'
              className='form-control'
              value={updateUserFormik.values.email}
              disabled
            />
          </div>
        </div>

        <div className='col-md-12'>
          <div className='form-group'>
            <label htmlFor='mobile'>Mobile</label>
            <input
              id='mobile'
              type='text'
              name='mobile'
              className='form-control'
              value={updateUserFormik.values.mobile}
              disabled
            />
          </div>
        </div>

        <div className='col-md-12'>
          <div className='form-group'>
            <label htmlFor='emp_code'>Employee Code</label>
            <input
              id='emp_code'
              type='text'
              name='emp_code'
              className='form-control'
              value={updateUserFormik.values.emp_code}
              disabled
            />
          </div>
        </div>

        <div className='col-md-12'>
          <div className='form-group'>
            <label htmlFor='department'>Department</label>
            <input
              id='department'
              type='text'
              name='department'
              className='form-control'
              value={updateUserFormik.values.department}
              disabled
            />
          </div>
        </div>

        <div className='col-md-12'>
          <div className='form-group'>
            <label htmlFor='password'>New Password</label>
            <input
              id='password'
              type='password'
              name='password'
              className='form-control'
              placeholder='Enter your Password'
              value={updateUserFormik.values.password}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.password &&
              updateUserFormik.errors.password && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updateUserFormik.errors.password}
                </div>
              )}
          </div>
        </div>
        <div className='col-md-12'>
          <div className='form-group'>
            <label htmlFor='wallet_balance'>Wallet Balance</label>
            <input
              id='wallet_balance'
              type='text'
              name='wallet_balance'
              className='form-control'
              value={"₹" + updateUserFormik.values.wallet_balance}
              disabled
            />
            <Link
              to='statement'
              style={{ color: "#ff5252", display: "block" }}
              className='mt-2 w-100 text-end'
            >
              Wallet Statement
            </Link>
          </div>
        </div>
        <div className='col-12 mt-2'>
          <button
            type='submit'
            className='btn btn-primary'
          >
            Update Information
          </button>
        </div>
      </div>
    </form>
  );

 
  // JSX for rendering user profile page
  return (
    <>
      <div
        className='content'
        style={{ background: "#f5f5f5" }}
      >
       
        <div
          className='section-body p-4 d-flex justify-content-center align-items-start'
          style={{ height: "100dvh" , overflow: 'auto' }}
        >
          <div className='card' style={{marginBottom : '5rem'}}>
            <div className='card-header'>
              <h5>User Information</h5>
            </div>
            <div className='card-body'>{user && renderUserInfoForm()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerProfile;
