import React, { useEffect, useState } from "react";
import styles from "./FoodOrder.module.css";

const FoodOrder = ({
  menuData,
  activeTab,
  dynamicHeight,
  setCartData,
  cartData,
  isAdminView,
  clearSearch
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [remarkVisibility, setRemarkVisibility] = useState({});


  useEffect(() => {
    clearSearchInput();
    setRemarkVisibility({});
  }, [clearSearch]);

  const cartList = localStorage.getItem("cartData")
    ? JSON.parse(localStorage.getItem("cartData"))
    : [];

  useEffect(() => {
    if (cartList?.length) {
      setCartData(cartList);
    }
  }, []);

  useEffect(() => {
    if (cartList[0]?.menu_type === "Fixed Meal") {
      console.log("fixed");
      setCartData([]);
      localStorage.setItem("cartData", JSON.stringify([]));
    }
  }, [activeTab]);

  const handleCartUpdate = (item, delta) => {
    if (delta === 0) return;
    const updatedCartData = [...cartData];
    const itemIndex = updatedCartData?.findIndex(
      (cartItem) => cartItem.id === item.id
    );

    if (itemIndex >= 0) {
      const newQuantity = updatedCartData[itemIndex].quantity + delta;
      if (newQuantity > 0) {
        updatedCartData[itemIndex].quantity = newQuantity;
      } else {
        updatedCartData.splice(itemIndex, 1);
      }
    } else if (delta > 0) {
      updatedCartData.push({ ...item, quantity: 1 });
    }

    setCartData(updatedCartData);
    localStorage.setItem("cartData", JSON.stringify(updatedCartData));
  };

  const handleSearchInput = (keyword) => {
    setSearchKeyword(keyword);
  };

  const clearSearchInput = () => {
    setSearchKeyword("");
  };

  const toggleRemarkVisibility = (itemId) => {
    setRemarkVisibility((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const handleRemarkChange = (itemId, remark) => {
    const updatedCartData = cartData.map((cartItem) =>
      cartItem.id === itemId ? { ...cartItem, remark } : cartItem
    );
    setCartData(updatedCartData);
    localStorage.setItem("cartData", JSON.stringify(updatedCartData));
  };

  const handleAdminClick = (item) => {
    const isItemInCart = cartData?.find((cartItem) => cartItem.id === item.id);
    handleCartUpdate(item, isItemInCart ? 0 : +1);
  };

  const filteredMenuData = menuData.filter(
    (item) =>
      item.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.category_name.toLowerCase().includes(searchKeyword.toLowerCase())
  );



  return (
    <div className={styles.foodOrderWrapper}>
      <div className={styles.inputWrapper}>
        <input
          className={styles.searchInput}
          type='text'
          placeholder='Search'
          value={searchKeyword}
          onChange={(e) => handleSearchInput(e.target.value)}
        />
        {searchKeyword && (
          <button
            className={styles.clearButton}
            onClick={clearSearchInput}
          >
            ×
          </button>
        )}
      </div>
      <div
        className={styles.itemsContainer}
        style={{ height: dynamicHeight }}
      >
        {filteredMenuData?.length ? (
          filteredMenuData.map((item) => (
            <div
              key={item.id}
              className={`${styles.item} ${
                cartData?.find((cartItem) => cartItem.id === item.id)
                  ? styles.inCart
                  : ""
              } ${isAdminView ? styles.adminHover : ""}`}
              style={{
                background: cartData?.find(
                  (cartItem) => cartItem.id === item.id
                )
                  ? "#dddddd"
                  : "#fff",
              }}
              onClick={() => isAdminView && handleAdminClick(item)}
            >
              <img
                src={process.env.REACT_APP_MEDIA_URL + "/" + item.image}
                alt={item.title}
                className={styles.itemImage}
              />
              <div className={styles.itemDetails}>
                <h3 className={styles.itemName}>{item.title}</h3>
                <p className={styles.itemDescription}>{item.description}</p>
                <div className={styles.itemFooter}>
                  <span className={styles.itemPrice}>₹ {item.price}</span>
                  {!isAdminView ? (
                    <div className={styles.quantityControl}>
                      <button
                        className={styles.quantityButton}
                        onClick={() => handleCartUpdate(item, -1)}
                        disabled={
                          !cartData?.find((cartItem) => cartItem.id === item.id)
                        }
                      >
                        -
                      </button>
                      <span className={styles.quantity}>
                        {cartData?.find((cartItem) => cartItem.id === item.id)
                          ?.quantity || 0}
                      </span>
                      <button
                        className={styles.quantityButton}
                        onClick={() => handleCartUpdate(item, +1)}
                      >
                        +
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className='d-flex w-100 justify-content-end'>
                  <button
                    className={styles.remarkButton}
                    onClick={() => toggleRemarkVisibility(item.id)}
                  >
                    {remarkVisibility[item.id] ||
                    cartData?.find((cartItem) => cartItem.id === item.id)
                      ?.remark?.length > 0
                      ? "Hide Remark"
                      : "Add Remark"}
                  </button>
                </div>
                {(remarkVisibility[item.id] ||
                  cartData?.find((cartItem) => cartItem.id === item.id)
                    ?.remark?.length > 0) && (
                  <div className={styles.remarkWrapper}>
                    <input
                      type='text'
                      className={styles.remarkInput}
                      placeholder='Add a remark'
                      value={
                        cartData?.find((cartItem) => cartItem.id === item.id)
                          ?.remark || ""
                      }
                      onChange={(e) =>
                        handleRemarkChange(item.id, e.target.value)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className='text-center w-100'>
            <p>No Results Found!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FoodOrder;
