import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import Select from "react-select";

const AddEditSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [users, setUsers] = useState([]);
  const [remainingDays, setRemainingDays] = useState();

  useEffect(() => {
    const getSubscription = async () => {
      try {
        if (id) {
          const res = await axiosPrivate.get(`subscription/${id}`);
          formik.setValues(res?.data[0]);
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    if (id) {
      getSubscription();
    }
  }, [id]);

  const fetchUsers = async () => {
    try {
      const response = await axiosPrivate.get("user");
      if (response.status === 200) {
        setUsers(
          response.data.data
            ?.sort((a, b) =>
              a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())
            )
            .map((user) => ({
              value: user.id,
              label: `${user.name} - ${user.emp_code}`,
            }))
        );
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    if (!id) {
      fetchUsers();
    }
  }, []);

  const subscriptionSchema = Yup.object({
    payment_mode: Yup.string().required("Payment mode is required"),
    meal: Yup.array()
      .min(1, "At least one menu item is required")
      .required("Menu items are required"),
    ...(!id && {
      user_id: Yup.string().required("User ID is required"),
    }),
    ...(!id && {
      include_sundays: Yup.number().required("Required Field"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      payment_mode: "",
      meal: [],
      ...(!id && { user_id: "" }),
      ...(!id && { include_sundays: 0 }),
    },
    validationSchema: subscriptionSchema,
    onSubmit: async (values) => {
      try {
        let valueObject = {
          meal: values.meal.map((item) => item.value),
          payment_mode: values?.payment_mode,
          total_amount: totalAmount,
        };
        let postObject = {};
        if (!id) {
          postObject = {
            meal: values.meal.map((item) => item.value),
            payment_mode: values?.payment_mode,
            total_amount: totalAmount,
            user_id: values.user_id,
            include_sundays: values?.include_sundays,
            total_days: remainingDays,
          };
        }
        if (id) {
          const response = await axiosPrivate.put(
            `subscription/${id}`,
            valueObject
          );
          if (response.status === 200) {
            navigate(-1);
            showSuccessToast("Subscription updated successfully");
          }
        } else {
          const res = await axiosPrivate.post(`subscription-by-hr`, postObject);
          if (res.status === 200) {
            navigate(-1);
            showSuccessToast("Subscription added successfully");
          }
        }
      } catch (err) {
        showErrorToast(
          err.response?.data?.message ||
            err.response?.data?.errors[0] ||
            err?.response?.data.errors[0].msg ||
            err?.message
        );
      }
    },
  });

  const { touched, errors } = formik;

  const fetchMenuItems = async () => {
    try {
      const response = await axiosPrivate.get("menu");
      if (response.status === 200) {
        const subscribedMenuIds = formik.values.menu_items?.map(
          (item) => item.id
        );
        const data = response.data?.data
          ?.filter((item) => item?.menu_type === "Fixed Meal")
          .filter((menu) => !subscribedMenuIds?.includes(menu.id));

        console.log(data);
        setMenuItems(
          data
            ?.sort((a, b) =>
              a?.title?.toLowerCase()?.localeCompare(b?.title?.toLowerCase())
            )
            .map((item) => ({
              value: item.id,
              label: `${item.title}   (Rs.${item.price})`,
            }))
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  // calculate total price of Subscription for current month

  useEffect(() => {
    if (formik?.values?.meal?.length > 0) {
      const remainingDays = calculateRemainingDays(
        formik.values.include_sundays
      );
      const totalPrice = calculateTotalPrice(formik.values.meal);
      const totalForRemainingDays = totalPrice * remainingDays;
      setTotalAmount(totalForRemainingDays);
    }
  }, [formik?.values?.include_sundays]);

  const calculateRemainingDays = (includeSundays) => {
    const today = new Date();
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let remainingDays = endOfMonth.getDate() - today.getDate() + 1; // Include today

    if (!includeSundays) {
      let count = 0;
      for (
        let d = new Date(today);
        d <= endOfMonth;
        d.setDate(d.getDate() + 1)
      ) {
        if (d.getDay() === 0) {
          // 0 is Sunday
          count++;
        }
      }
      remainingDays -= count;
    }

    setRemainingDays(remainingDays);
    return remainingDays;
  };

  const calculateTotalPrice = (selectedItems) => {
    return selectedItems.reduce((total, item) => {
      const price = parseFloat(
        item.label.match(/\(Rs\.(\d+(\.\d{1,2})?)\)/)[1]
      );
      return total + price;
    }, 0);
  };

  const handleMealChange = (selectedOptions) => {
    formik.setFieldValue("meal", selectedOptions);
    const remainingDays = calculateRemainingDays(formik.values.include_sundays);
    const totalPrice = calculateTotalPrice(selectedOptions);
    const totalForRemainingDays = totalPrice * remainingDays;
    setTotalAmount(totalForRemainingDays);
  };

  // Total amount of remaining days function ended.

  const paymentModeOptions = [
    { value: "wallet", label: "Wallet" },
    { value: "upi", label: "UPI" },
    { value: "salary", label: "Salary" },
  ];

  const includeOptions = [
    { value: 0, label: "No" },
    { value: 1, label: "Yes" },
  ];

  return (
    <div className='content'>
      {id && (
        <div className='card mt-1'>
          <div className='card-header'>
            <strong>Subscription Details</strong>
          </div>
          <div className='card-body'>
            <table className='table'>
              <tbody>
                <tr>
                  <th>User Name</th>
                  <td>{formik.values.user_name}</td>
                </tr>
                <tr>
                  <th>Meal(s)</th>
                  <td>
                    {formik.values?.menu_items
                      ?.map((item) => item.title)
                      .join(", ")}
                  </td>
                </tr>
                <tr>
                  <th>Start Date</th>
                  <td>{formik.values.start_date}</td>
                </tr>
                <tr>
                  <th>Sundays Included</th>
                  <td>{formik.values.include_sundays == 1 ? "YES" : "NO"}</td>
                </tr>
                <tr>
                  <th>Subscription Ends in</th>
                  <td>{formik.values.remainingDays} days</td>
                </tr>

                <tr>
                  <th>Payment Mode</th>
                  <td>{formik.values.payment_mode}</td>
                </tr>
                <tr>
                  <th>Payment Status</th>
                  <td>{formik.values.payment_status}</td>
                </tr>
                <tr>
                  <th>Total Amount</th>
                  <td>{formik.values.total_amount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className='section-body d-flex justify-content-center align-items-center mt-2'>
        <div className='card'>
          <form onSubmit={formik.handleSubmit}>
            <div className='card-header'>
              <strong>{id ? "Edit Meal" : "Add Meal"}</strong>
            </div>
            <div className='card-body'>
              <div className='row clearfix'>
                {!id && (
                  <div className='col-md-6 col-sm-12'>
                    <div className='form-group'>
                      <label className='form-label'>Select User</label>
                      <Select
                        name='user_id'
                        options={users}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        value={users.find(
                          (option) => option.value === formik.values.user_id
                        )}
                        onChange={(selectedOptions) =>
                          formik?.setFieldValue(
                            "user_id",
                            selectedOptions?.value
                          )
                        }
                      />

                      {touched.user_id && errors.user_id ? (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.user_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}

                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Select Meals</label>
                    <Select
                      isMulti
                      name='meal'
                      options={menuItems}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      value={formik?.values?.meal}
                      onChange={handleMealChange}
                    />

                    {touched.meal && errors.meal ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.meal}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Payment Mode</label>
                    <Select
                      name='payment_mode'
                      options={paymentModeOptions}
                      className='basic-single-select'
                      classNamePrefix='select'
                      value={paymentModeOptions.find(
                        (option) => option.value === formik.values.payment_mode
                      )}
                      onChange={(selectedOptions) =>
                        formik?.setFieldValue(
                          "payment_mode",
                          selectedOptions.value
                        )
                      }
                    />
                    {formik.touched.payment_mode &&
                    formik.errors.payment_mode ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.payment_mode}
                      </div>
                    ) : null}
                  </div>
                </div>
                {!id && (
                  <div className='col-md-6 col-sm-12'>
                    <div className='form-group'>
                      <label className='form-label'>Include sundays ?</label>
                      <Select
                        name='include_sundays'
                        options={includeOptions}
                        className='basic-single-select'
                        classNamePrefix='select'
                        value={includeOptions.find(
                          (option) =>
                            option.value === formik.values.include_sundays
                        )}
                        onChange={(selectedOptions) => {
                          formik?.setFieldValue(
                            "include_sundays",
                            selectedOptions.value
                          );
                          const remainingDays = calculateRemainingDays(
                            selectedOptions.value
                          );
                          const totalPrice = calculateTotalPrice(
                            formik.values.meal
                          );
                          const totalForRemainingDays =
                            totalPrice * remainingDays;
                          setTotalAmount(totalForRemainingDays);
                        }}
                      />

                      {formik.touched.include_sundays &&
                      formik.errors.include_sundays ? (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.include_sundays}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                <div
                  className='col-12 text-right mt-2'
                  style={{ fontSize: "12px" }}
                >
                  Total Amount For This Month :{" "}
                  <span style={{ color: "var(--red)" }}>{totalAmount}</span>
                </div>

                <div className='col-12 text-right'>
                  <hr className='mt-4' />
                  <button
                    type='button'
                    id='button_1'
                    className='btn btn-secondary mx-1'
                    onClick={() => navigate("/orders")}
                  >
                    CLOSE
                  </button>

                  <button
                    type='submit'
                    id='button_2'
                    className='btn btn-primary'
                  >
                    Place Order
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditSubscription;
