import React, { useEffect } from "react";
import TopNavBar from "../../Layouts/TopNavBar";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const AddEditRole = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // Fetch Plant data if id is provided and user wants to update
  useEffect(() => {
    const getPlant = async () => {
      try {
        if (id) {
          const res = await axiosPrivate.get(`roles/${id}`);
          formik.setValues(res?.data?.data);
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    if (id) {
      getPlant();
    }
  }, [id]);

  // Form validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Role name is required"),
  });

  // Initial form values
  const initialValues = {
    name: "",
  };

  // Form submission handling
  const handleSubmit = async (values) => {
    try {
      if (id) {
        await axiosPrivate.put(`roles/${id}`, values);
        navigate("/roles");
        showSuccessToast("Role updated successfully");
      } else {
        await axiosPrivate.post(`roles`, values);
        navigate("/roles");
        showSuccessToast("Role added successfully");
      }
    } catch (err) {
      showErrorToast(err.message);
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className='content'>
      <div
        className='section-body d-flex justify-content-center align-items-center'
        style={{ minHeight: `calc(100dvh - 106px)` }}
      >
        <div
          className='card '
          style={{ width: "30%" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className='card-header'>
              <strong>Add/Edit Role</strong>
            </div>
            <div className='card-body'>
              <div className='row clearfix'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Role Name</label>
                    {/* Role name input */}
                    <input
                      id='name'
                      name='name'
                      type='text'
                      className='form-control'
                      placeholder='Role Name *'
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue("name", formik.values.name.trim());
                      }}
                      value={formik.values.name}
                    />
                    {/* Display error message if there's a validation error */}
                    {formik.touched.name && formik.errors.name && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12 text-right'>
                  <hr className='mt-4' />
                  {/* Close button */}
                  <button
                    type='button'
                    className='btn btn-secondary mx-1'
                    onClick={() => navigate("/roles")}
                  >
                    CLOSE
                  </button>
                  {/* Submit button */}
                  <button
                    type='submit'
                    className='btn btn-primary'
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditRole;
