import React, { useEffect, useState } from "react";
import styles from "./Checkout.module.css";
import CustomerFooter from "../CustomerFooter/CustomerFooter";

import BackButton from "../../assets/images/backButton.svg";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast } from "../../utils/Toaster"; // Assuming Toaster is a utility for showing error messages
import QRCode from "react-qr-code";

const Checkout = () => {
  const [checkoutData, setCheckoutData] = useState([]);
  const [totalPriceRegular, setTotalPriceRegular] = useState(0);
  // const [fixedMealData, setFixedMealData] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [debouncedQuantity, setDebouncedQuantity] = useState(null); // For debouncing
  const [isFixedMeal, setIsFixedMeal] = useState(false);



  const [cartListData , setCartListData] = useState([])

  const { isLoading, setIsLoading, includeSundays } = useLoader();

  const cartData = JSON.parse(localStorage.getItem("cartData"));


  useEffect(() => {
    if (cartData) {
      setCartListData(cartData);
    }
  }, []);

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    let total = 0;
    cartListData?.forEach(item => {
      total += (item?.price * item?.quantity);
    })

    setTotalPriceRegular(total)
  }, [cartListData]);

  const getCheckoutData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(`/checkout/${userData?.id}`);
      if (response.status === 200) {
        setCheckoutData(response?.data?.data);

        if (response?.data?.data?.items[0]?.menu_type === "Regular") {
          setTotalPriceRegular(response?.data?.data?.total_price);
        }
        const data = JSON.parse(localStorage.getItem("cartData"));

        if (response?.data?.data?.items[0]?.menu_type === "Fixed Meal" && data[0]?.menu_type === "Fixed Meal") {
          setIsFixedMeal(true);
        }
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCheckoutData();
  }, []);

  const updateCheckoutData = () => {
    getCheckoutData();
  };

  const handleQuantityChange = (id, delta) => {
    const updatedItemsList = checkoutData?.items?.map((item) =>
      item.menu_id === id
        ? { ...item, quantity: Math.max(0, Number(item.quantity) + delta) }
        : item
    );
    setCheckoutData(updatedItemsList);

    const updatedItem = updatedItemsList?.find((item) => item.menu_id === id);
    if (updatedItem) {
      const newQuantity = Math.max(0, updatedItem.quantity);
      setDebouncedQuantity({
        userId: userData?.id,
        menuId: id,
        quantity: newQuantity,
      });
    }
  };

  useEffect(() => {
    if (debouncedQuantity) {
      const handler = setTimeout(async () => {
        try {
          await axiosPrivate.post("/cart", {
            user_id: debouncedQuantity.userId,
            menu_id: debouncedQuantity.menuId,
            quantity: debouncedQuantity.quantity,
          });
          await getCheckoutData();
        } catch (error) {
          showErrorToast(error.message);
        }
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [debouncedQuantity]);



   const handleCartUpdate = (item, delta) => {
     const updatedCartData = [...cartListData];
     const itemIndex = updatedCartData?.findIndex(
       (cartItem) => cartItem.id === item.id
     );

     if (itemIndex >= 0) {
       const newQuantity = updatedCartData[itemIndex].quantity + delta;
       if (newQuantity > 0) {
         updatedCartData[itemIndex].quantity = newQuantity;
       } else {
         updatedCartData.splice(itemIndex, 1);
       }
     } else if (delta > 0) {
       updatedCartData.push({ ...item, quantity: 1 });
     }

     setCartListData(updatedCartData)

    localStorage.setItem('cartData', JSON.stringify(updatedCartData))
   };

 

  return (
    <>
      {orderDetails?.payment_status === "paid" ? (
        <>
          {" "}
          <div className={styles.bodyWrapper}>
            <div className={styles.header}>
              <Link to='/order-food'>
                <img
                  src={BackButton}
                  alt='back_button'
                />
              </Link>
              <h2>QR Code</h2>
            </div>
            <div
              className={`${styles.qrContainer} d-flex justify-content-center align-items-center flex-column`}
            >
              <QRCode
                value={orderDetails?.uu_id}
                size={320}
              />
              <span
                className='mt-2'
                style={{ color: "#555" }}
              >
                Scan this QR to order your meal for today!
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.bodyWrapper}>
            <div className={styles.header}>
              <Link to='/order-food'>
                <img
                  src={BackButton}
                  alt='back_button'
                />
              </Link>
              <h2>Checkout</h2>
            </div>
            <div className={styles.itemsContainer}>
              {cartListData?.length ? (
                cartListData?.map((item) => (
                  <div
                    key={item?.id}
                    className={styles.item}
                  >
                    <img
                      src={process.env.REACT_APP_MEDIA_URL + `${item?.image}`}
                      alt={item?.title}
                      className={styles.itemImage}
                    />
                    <div className={styles.itemDetails}>
                      <div className='d-flex flex-column'>
                        <h3 className={styles.itemName}>{item.title}</h3>
                        <span className={styles.itemPrice}>₹ {item.price}</span>
                        {item.remark && (
                          <p className={styles.itemRemark}>
                            Remark: {item.remark}
                          </p>
                        )}
                      </div>
                      <div className={styles.quantityControl}>
                        {item?.menu_type === "Fixed Meal" ? (
                          <button
                            className={styles.quantityButton}
                            onClick={() => {
                              handleCartUpdate(item, -1);
                              handleQuantityChange(item?.id, -1);
                            }}
                            disabled={item.quantity === 0} // Prevent decreasing below 1
                          >
                            -
                          </button>
                        ) : (
                          <>
                            <button
                              className={styles.quantityButton}
                              onClick={() => handleCartUpdate(item, -1)}
                              disabled={item.quantity === 0} // Prevent decreasing below 1
                            >
                              -
                            </button>
                            <span className={styles.quantity}>
                              {item.quantity}
                            </span>
                            <button
                              className={styles.quantityButton}
                              onClick={() => handleCartUpdate(item, +1)}
                            >
                              +
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span>Please Choose your Order First!</span>
              )}
            </div>

            <div className={styles.billDetails}>
              <h3>Bill Details</h3>
              <div className={styles.billItems}>
                <div className={styles.billItem}>
                  {includeSundays && isFixedMeal ? (
                    <span>
                      Sub Total (for remaining{" "}
                      {checkoutData?.days_including_sunday} days{" "}
                      <strong>including sunday</strong>)
                    </span>
                  ) : !includeSundays && isFixedMeal ? (
                    <span>
                      Sub Total (for remaining{" "}
                      {checkoutData?.days_excluding_sunday} days{" "}
                      <strong>excluding sundays</strong>)
                    </span>
                  ) : (
                    <span>Sub Total</span>
                  )}

                  {includeSundays && isFixedMeal ? (
                    <span>
                      ₹{" "}
                      {checkoutData?.days_including_sunday *
                        checkoutData?.total_price}
                    </span>
                  ) : !includeSundays && isFixedMeal ? (
                    <span>
                      ₹{" "}
                      {checkoutData?.days_excluding_sunday *
                        checkoutData?.total_price}
                    </span>
                  ) : (
                    <span>₹ {totalPriceRegular} </span>
                  )}
                </div>

                <div className={styles.billTotal}>
                  <span>
                    Total
                    {isFixedMeal && includeSundays ? (
                      <span className='ml-3'>
                        {checkoutData?.total_price} x{" "}
                        {checkoutData?.days_including_sunday}
                      </span>
                    ) : isFixedMeal && !includeSundays ? (
                      <span className='ml-3'>
                        {checkoutData?.total_price} x{" "}
                        {checkoutData?.days_excluding_sunday}
                      </span>
                    ) : null}
                  </span>

                  {isFixedMeal && includeSundays ? (
                    <span>
                      ₹{" "}
                      {checkoutData?.days_including_sunday *
                        checkoutData?.total_price}
                    </span>
                  ) : isFixedMeal && !includeSundays ? (
                    <span>
                      {checkoutData?.days_excluding_sunday *
                        checkoutData?.total_price}
                    </span>
                  ) : (
                    <span>₹ {totalPriceRegular}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <CustomerFooter
            isCheckoutPage={true}
            isFixedMeal={isFixedMeal}
            checkoutData={checkoutData?.items}
            totalPrice={
              includeSundays && isFixedMeal
                ? checkoutData?.days_including_sunday *
                  checkoutData?.total_price
                : !includeSundays && isFixedMeal
                ? checkoutData?.days_excluding_sunday *
                  checkoutData?.total_price
                : totalPriceRegular
            }
            orderDetails={orderDetails}
            includeSundays={includeSundays}
            setOrderDetails={setOrderDetails}
            updateCheckoutData={updateCheckoutData}
          />
        </>
      )}
    </>
  );
};

export default Checkout;
