import { Fragment } from "react";

import { Outlet } from "react-router-dom";

import { ToastContainer } from "react-toastify";


const AuthMain = () => {
    return (
        <Fragment>
        <div className="font-montserrat">  
            <div className="auth">   
                <Outlet />
            </div>
            </div>
            <ToastContainer/>
        </Fragment>
    );
};

export default AuthMain;
