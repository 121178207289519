/**
 * This is the main entry point of the application.
 * We are using ReactDOM to render the application into
 * the root element of the HTML.
 */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "react-flow-renderer/dist/style.css";
import PermissionsProvider from "./context/permissions/PermissionsProvider.js";
import LoaderProvider from "./context/Loader/LoaderProvider.js";

/**
 * Here we are rendering the application.
 * We are using the createRoot method of ReactDOM to
 * create a root element and render the application
 * into it.
 */

// render the application

 /**
   * The application is wrapped in the LoaderProvider
   * and the PermissionsProvider.
   *
   * The LoaderProvider is responsible for showing
   * a loading spinner when a request is ongoing.
   *
   * The PermissionsProvider is responsible for
   * fetching and providing permissions to the
   * application.
   */

ReactDOM.createRoot(document.getElementById("root")).render(
 
  <LoaderProvider>
    <PermissionsProvider>
      <App />
    </PermissionsProvider>
  </LoaderProvider>
);
