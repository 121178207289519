import { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { PiUsersThree, PiUserList } from "react-icons/pi";
import { GrDocumentConfig } from "react-icons/gr";
import { MdOutlineCountertops, MdOutlineRestaurantMenu } from "react-icons/md";
import { BsFillMenuAppFill } from "react-icons/bs";
import { IoWalletOutline } from "react-icons/io5";
import { IoFastFoodSharp } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";
import { usePermissions } from "../context/permissions/PermissionsProvider";
import { CiViewList } from "react-icons/ci";

const SideBar = () => {
  const location = useLocation(); // Get current location
  const [isMastersOpen, setIsMastersOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  const [activeTab, setActiveTab] = useState("");
  
  useEffect(() => {
    // Set active tab based on current pathname
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const toggleMasters = () => {
    setIsMastersOpen((prev) => !prev);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const mastersPaths = [
    "/users",
    "/roles",
    "/user-wallet",
    "/menu",
    "/menu-category",
    "/food-counter",
    "/user-wallet",
    "reports"
  ];

  // Determine if current path is in the Masters array
  useEffect(() => {
    if (mastersPaths.includes(location.pathname)) {
      setIsMastersOpen(true);
    }
  }, []);

  return (
    <Fragment>
      <div
        id='header_top'
        className='header_top false'
      >
        <div className='container'>
          <div
            className='hleft'
            style={{ overflow: "scroll" }}
          >
            <div>
              {/* Dashboard Link */}
              {/* <ul>
                <li className={activeTab === "/" ? "active-box" : ""}>
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                      activeTab === "/" ? "active " : ""
                    }`}
                    to="/"
                    onClick={() => handleTabClick("/")}
                  >
                    <RxDashboard size={20} />
                    <span className="sidebar_text">Dashboard</span>
                  </Link>
                </li>
              </ul> */}

              {/* Orders Link */}
              <ul>
                <li className={activeTab === "/orders" ? "active-box" : ""}>
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                      activeTab === "/orders" ? "active " : ""
                    }`}
                    to='/orders'
                    onClick={() => handleTabClick("/orders")}
                  >
                    <IoFastFoodSharp size={20} />
                    <span className='sidebar_text'>Orders</span>
                  </Link>
                </li>
              </ul>
              <hr />
              <ul>
                <li
                  className={activeTab === "/user-wallet" ? "active-box" : ""}
                >
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                      activeTab === "/user-wallet" ? "active" : ""
                    }`}
                    to='/user-wallet'
                    onClick={() => handleTabClick("/user-wallet")}
                  >
                    <IoWalletOutline size={20} />
                    <span className='sidebar_text'>Wallet</span>
                  </Link>
                </li>
              </ul>
              <hr />
              <ul>
                <li className={activeTab === "/reports" ? "active-box" : ""}>
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                      activeTab === "/reports" ? "active " : ""
                    }`}
                    to='/reports'
                    onClick={() => handleTabClick("/reports")}
                  >
                    <TbReportAnalytics size={20} />
                    <span className='sidebar_text'>Reports</span>
                  </Link>
                </li>
              </ul>
              <hr />
              <ul>
                <li className={activeTab === "/audit-logs" ? "active-box" : ""}>
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                      activeTab === "/audit-logs" ? "active " : ""
                    }`}
                    to='/audit-logs'
                    onClick={() => handleTabClick("/audit-logs")}
                  >
                    <CiViewList size={20} />
                    <span className='sidebar_text'>Audit Logs</span>
                  </Link>
                </li>
              </ul>
              <hr />
              {/* Masters List */}
              <ul className={`dropdown ${isMastersOpen ? "show" : ""} w-100`}>
                <li>
                  <span onClick={toggleMasters}>
                    <div
                      aria-current='page'
                      className='list-a nav-link icon app_inbox d-flex flex-column'
                      style={{ color: "white" }}
                    >
                      <GrDocumentConfig size={20} />
                      <span className='sidebar_text'>Masters</span>
                      <i
                        className={`caret-left ${
                          isMastersOpen ? "active" : ""
                        }`}
                      ></i>
                    </div>
                  </span>
                  {/* Nested Masters List */}
                  <ul className={`collapse ${isMastersOpen ? "show" : ""}`}>
                    <li className={activeTab === "/roles" ? "active-box" : ""}>
                      <Link
                        className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                          activeTab === "/roles" ? "active" : ""
                        }`}
                        to='/roles'
                        onClick={() => handleTabClick("/roles")}
                      >
                        <PiUserList size={20} />
                        <span className='sidebar_text'>Roles</span>
                      </Link>
                    </li>
                    <li className={activeTab === "/users" ? "active-box" : ""}>
                      <Link
                        className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                          activeTab === "/users" ? "active" : ""
                        }`}
                        to='/users'
                        onClick={() => handleTabClick("/users")}
                      >
                        <PiUsersThree size={20} />
                        <span className='sidebar_text'>Users</span>
                      </Link>
                    </li>
                    <li
                      className={
                        activeTab === "/food-counter" ? "active-box" : ""
                      }
                    >
                      <Link
                        className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                          activeTab === "/food-counter" ? "active" : ""
                        }`}
                        to='/food-counter'
                        onClick={() => handleTabClick("/food-counter")}
                      >
                        <MdOutlineCountertops size={20} />
                        <span className='sidebar_text'>Food Counter</span>
                      </Link>
                    </li>
                    <li
                      className={
                        activeTab === "/menu-category" ? "active-box" : ""
                      }
                    >
                      <Link
                        className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                          activeTab === "/menu-category" ? "active" : ""
                        }`}
                        to='/menu-category'
                        onClick={() => handleTabClick("/menu-category")}
                      >
                        <BsFillMenuAppFill size={20} />
                        <span className='sidebar_text'>Menu Category</span>
                      </Link>
                    </li>
                    <li className={activeTab === "/menu" ? "active-box" : ""}>
                      <Link
                        className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                          activeTab === "/menu" ? "active" : ""
                        }`}
                        to='/menu'
                        onClick={() => handleTabClick("/menu")}
                      >
                        <MdOutlineRestaurantMenu size={20} />
                        <span className='sidebar_text'>Menu</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SideBar;
