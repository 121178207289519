import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosPrivate from "../hooks/axiosPrivate";
import { showErrorToast } from "../utils/Toaster";
import Logo from "../assets/images/customer_logo.png"
import { axiosPublic } from "../hooks/axiosPublic";
import { usePermissions } from "../context/permissions/PermissionsProvider";

const Header = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage?.getItem("userData")) || {};


  const { authPermissions } = usePermissions();

  const logoutHandler = () => {
    // Remove user data from local storage
    localStorage.removeItem("userData");
    // Navigate to the login page
    navigate("/login");
  };

  useEffect(() => {
    // Redirect to login page if userData is not present or essential properties are missing
    if (!userData || !userData.id || !userData.userName) {
      navigate("/login");
      window.location.reload();
    }
  }, [navigate, userData]);

  return (
    <div
      id='page_top'
      className='container-fluid'
      style={{
        padding: "0px 20px",
        position: "sticky",
        top: "0px",
        zIndex: "100",
        background: "#ffffff",
      }}
    >
      <div className='page-header'>
        {/* Left section */}
        <div className='left'>
          <div className='input-group xs-hide '>
            <Link
              to='/'
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={Logo}
                alt='logo'
              />
            </Link>
          </div>
        </div>
        {/* Right section */}
        <div className='right'>
          <div className=' d-flex justify-content-center align-items-center'>
           {(userData?.role_name === 'billing person' || userData?.role_name === 'Billing Person') ? <div>
              <Link
                to='/orders'
                style={{
                  textDecoration: "none",
                  color: "#4D5052",
                  fontSize: "14px",
                  marginRight: "30px",
                }}
              >
               Orders
              </Link>
            </div> : null}
            {authPermissions?.includes('Wallet-list') ?   <div>
              <Link
                to='/user-wallet'
                style={{
                  textDecoration: "none",
                  color: "#4D5052",
                  fontSize: "14px",
                  marginRight: "30px",
                }}
              >
                Wallet Recharge
              </Link>
            </div> : null}
          

            {/* User profile dropdown */}
            <div className='dropdown d-flex'>
              <a
                href='/#'
                className='nav-link icon d-none d-flex btn btn-default btn-icon ml-1 justify-content-center align-items-center'
                data-toggle='dropdown'
              >
                <i className='fa fa-user'></i>
                <span
                  style={{
                    marginTop: "1px",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                >
                  {userData.userName}
                </span>
              </a>
              <div className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'>
                <a
                  className='dropdown-item'
                  href='/profile'
                >
                  <i className='dropdown-icon fe fe-user'></i> Profile
                </a>
                <a
                  className='dropdown-item'
                  onClick={logoutHandler}
                  href='# '
                >
                  <i className='dropdown-icon fe fe-log-out'></i> Sign out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
